.mad-footer-container {
  .mad-resources-container {
    padding: 50px 220px;
    min-height: 500px;
    color: #8560a7;

    @media screen and (max-width: 1400px) {
      padding: 30px 150px;
    }

    @media screen and (max-width: 1200px) {
      padding: 30px 100px;
    }

    @media screen and (max-width: 991px) {
      padding: 30px 45px;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      overflow: hidden;
      padding: 30px;
    }

    p {
      margin: 0;
    }

        .image-link {
          margin-top: 10px;
    
          a {
            display: flex;
            align-items: center;
    
            .text-wrapper {
              padding: 1rem;
    
              h4 {
                font-size: 16px;
                margin: 0;
              }
    
              p {
                font-size: 14px;
              }
            }
          }
    
          img {
            width: 20%;
          }
        }

    .mad-resource {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      h4 {
        font-family: "Lato", sans-serif;
      }

      .mad-social-links {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .image-link-wrapper {
        .image-link {
          margin-top: 10px;

          a {
            display: flex;
            align-items: center;

            .text-wrapper {
              padding: 1rem;

              h4 {
                font-size: 16px;
                margin: 0;
              }

              p {
                font-size: 14px;
              }
            }
          }

          img {
            width: 20%;
          }
        }
      }
    }

    form {
      label {
        width: 100%;
      }

      input {
        width: 100%;
        border-radius: 5px;
        border: 0;
        height: 35px;
        padding: 5px 10px;
      }

      textarea {
        width: 100%;
        border-radius: 5px;
        border: 0;
        padding: 5px 10px;
      }

      .btn {
        float: right;
        width: 100%;
        color: #ffffff;
      }
    }
  }

  .mad-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    padding: 30px 190px;
    color: #e9e0f9;
    font-size: 14px;
    background-color: #8560a7;

    @media screen and (max-width: 991px) {
      padding: 30px 45px;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      padding: 30px 0;
    }

    h4 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    a {
      color: #e9e0f9;
      margin: 5px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
